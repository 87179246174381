import React from 'react';
import { graphql } from 'gatsby';
import '../fragments';
import { FundCharacteristics } from '../graphql-fragments/Fund';

import EntityPage from './EntityPage';
import {
  renderBoolean,
  renderFundDuration,
  renderFundInvestmentSectors,
  renderMinimumTicketSize,
  renderMonthAndYear,
  renderPercentageWithExplainer,
  renderSetUpFee,
  renderTargetSize,
} from '../utils/characteristicsRenderFunctions';
import { ENTITY_TYPES_WITH_REVIEWS } from '../constants';
import { RawPortableText } from '../types';

export const query = graphql`
  query GetFundPageById($id: String!) {
    sanityFund(id: { eq: $id }) {
      ...Fund
    }
    sanityFundPage {
      sendAMessageForm {
        introText
        _rawWarningText(resolveReferences: { maxDepth: 6 })
        bottomNoteText
      }
      bookACallMessageForm {
        introText
        _rawWarningText(resolveReferences: { maxDepth: 6 })
        bottomNoteText
      }
    }
  }
`;

interface FundPageProps {
  data: {
    sanityFund: FundCharacteristics;
    sanityFundPage?: {
      sendAMessageForm?: {
        introText?: string;
        _rawWarningText?: RawPortableText;
        bottomNoteText?: string;
      };
      bookACallMessageForm?: {
        introText?: string;
        _rawWarningText?: RawPortableText;
        bottomNoteText?: string;
      };
    };
  };
}

const FundPage = ({ data }: FundPageProps): React.ReactElement => {
  const {
    _id,
    slug,
    hubspotInternalValue,
    logoImage,
    personImage,
    name,
    personName,
    mainVideoUrl,
    approved,
    introText,
    _rawDescription,
    bookIntroCallUrl,
    fundInvestmentSectors,
    strategyOverview,
    minimumTicketSize,
    firstClosePassed,
    setUpFee,
    managementFee,
    performanceFee,
    targetAnnualReturns,
    targetReturnsUponExit,
    subscriptionDeadline,
    targetSize,
    monthEstablished,
    fundDuration,
    fatcaAndPfic,
    seo,
  } = data.sanityFund;

  const fundCharacteristics = [
    { title: 'Auto Book Intro Call', value: bookIntroCallUrl && renderBoolean(!!bookIntroCallUrl) },
    { title: 'Investment Sectors', value: renderFundInvestmentSectors(fundInvestmentSectors) },
    { title: 'Strategy Overview', value: strategyOverview },
    { title: 'Minimum Ticket Size', value: renderMinimumTicketSize(minimumTicketSize) },
    { title: 'First Close Passed', value: renderBoolean(firstClosePassed) },
    { title: 'Set Up Fee', value: renderSetUpFee(setUpFee) },
    { title: 'Management Fee', value: renderPercentageWithExplainer(managementFee) },
    { title: 'Performance Fee', value: renderPercentageWithExplainer(performanceFee) },
    { title: 'Target Annual Returns', value: renderPercentageWithExplainer(targetAnnualReturns) },
    {
      title: 'Target Returns Upon Exit',
      value: renderPercentageWithExplainer(targetReturnsUponExit),
    },
    { title: 'Subscription Deadline', value: renderMonthAndYear(subscriptionDeadline) },
    { title: 'Target Fundraise', value: renderTargetSize(targetSize) },
    { title: 'Month Established', value: renderMonthAndYear(monthEstablished) },
    { title: 'Fund Duration', value: renderFundDuration(fundDuration) },
    { title: 'US-investors (PFIC)', value: renderBoolean(fatcaAndPfic) },
  ].filter(Boolean);

  return (
    <EntityPage
      entityType="fund"
      entityId={_id}
      slug={slug.current}
      hubspotInternalValue={hubspotInternalValue}
      isApproved={approved}
      hasResponsiblePerson={true}
      personImage={personImage}
      personName={personName}
      image={logoImage}
      topic={'fund'}
      title={name}
      text={introText}
      content={_rawDescription}
      characteristics={fundCharacteristics}
      videoUrl={mainVideoUrl}
      hasReviews={ENTITY_TYPES_WITH_REVIEWS.includes('fund')}
      sendAMessageFormTexts={data.sanityFundPage?.sendAMessageForm}
      bookACallFormTexts={data.sanityFundPage?.bookACallMessageForm}
      seo={seo}
    />
  );
};

export default FundPage;
